.shop-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.shop-content {
    background: linear-gradient(145deg, #ff69b4, #ff1493);
    padding: 2rem;
    border-radius: 15px;
    width: 80%;
    max-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.shop-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    color: white;
}

.items-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
}

.shop-item {
    background: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.2s;
}

.shop-item:hover {
    transform: translateY(-5px);
}

.shop-item img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.shop-item h3 {
    margin: 0.5rem 0;
    color: #333;
}

.shop-item p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
}

.price {
    font-weight: bold;
    color: #ff1493;
    margin-bottom: 1rem;
}

.buy-button {
    background: #ff1493;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s;
}

.buy-button:hover:not(:disabled) {
    background: #ff69b4;
}

.buy-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.close-button {
    background: #333;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 1.1rem;
}

.close-button:hover {
    background: #444;
}
