.introSection {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainTitle {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
}

#game-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: #f0f0f0; /* Light grey background to see the container */
}

.rowCol {
    width: 100%;
    margin: 0;
}

.loginField {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%; /* or any specific width like 320px */
    max-width: 400px; /* optional: prevents the form from getting too wide */
}
