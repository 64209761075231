.hyperspace-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    perspective: 1000px;
    z-index: 1000;
    background: black;
    overflow: hidden;
    display: none;
}

.hyperspace-container.active {
    display: block;
}

.star {
    position: absolute;
    width: 1px;
    height: 1px;
    background: white;
    border-radius: 50%;
    animation: hyperspace-star 2s linear; /* Removed 'infinite'*/
    transform-origin: center center -50px;
}

@keyframes hyperspace-star {
    0% {
        transform: rotateY(0deg) translateZ(0);
        opacity: 0;
        width: 1px;
        height: 1px;
    }
    30% {
        opacity: 1;
    }
    100% {
        transform: rotateY(0deg) translateZ(1000px);
        opacity: 0;
        width: 3px;
        height: 50px;
        background: linear-gradient(to bottom,
        rgba(255, 182, 193, 0),
        rgba(255, 182, 193, 1) 50%,
        rgba(255, 182, 193, 0));
    }
}

/*
.hyperspace-container.active {
    display: block;
}

.hyperspace-container.ending {
    animation: fade-out 1s ease-out forwards;
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}*/

